import React, { useState } from 'react';

import MenuCertificateImage from 'assets/images/pages/menu/Menu Certificate.png';
import MenuCoursesImage from 'assets/images/pages/menu/Menu Courses.png';
import MenuCoverImage from 'assets/images/pages/menu/Menu Cover Letter.png';
import MenuResumeImage from 'assets/images/pages/menu/Menu Resume Builder.png';
import MenuSkillImage from 'assets/images/pages/menu/Menu Skill Tree.png';
import MenuVIPImage from 'assets/images/pages/menu/Menu VIP.png';

import classes from './FeatureMenu.module.scss';


const FeatureMenu = () => {

    const MenuItems = [
        {
            id: 1,
            title: 'Skill Tree',
            image: MenuSkillImage.src,
            description: 'Empower Your Career: Unlock Your Potential with the Pharma Skill Tree',
        },
        {
            id: 2,
            title: 'Courses',
            image: MenuCoursesImage.src,
            description: 'Empower Your Career: Unlock Your Potential with the Pharma Skill Tree',
        },
        {
            id: 3,
            title: 'Certificate',
            image: MenuCertificateImage.src,
            description: 'Empower Your Career: Unlock Your Potential with the Pharma Skill Tree',
        },
        {
            id: 4,
            title: 'VIP Consultation meeting',
            image: MenuVIPImage.src,
            description: 'Empower Your Career: Unlock Your Potential with the Pharma Skill Tree',
        },
        {
            id: 5,
            title: 'Coverletter Builder',
            image: MenuCoverImage.src,
            description: 'Empower Your Career: Unlock Your Potential with the Pharma Skill Tree',
        },
        {
            id: 6,
            title: 'Resume Builder ',
            image: MenuResumeImage.src,
            description: 'Empower Your Career: Unlock Your Potential with the Pharma Skill Tree',
        },
    ];

    const [activeItem, setActiveItem] = useState(0);


    return (
        <div className={`${classes.wrapper} row`}>
            <div className="col-6">
                <div className={classes['item-wrapper']}>
                    <div className="row">
                        <div className="col-5">
                            <div className={classes['item-cat']}>
                                Learning
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(0)} href='https://pharmuni.com/skill-tree/'>
                                    Skill Tree
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(1)} href='https://pharmuni.com/pharmuni-courses/'>
                                    Courses
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(2)} href='https://pharmuni.com/pharmuni-certificate/'>
                                    Certificate
                                </a>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className={classes['item-cat']}>
                                Career development
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(3)} href='https://pharmuni.com/vip-consultation-meeting/'>
                                    VIP Consultation Meeting
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(4)} href='https://pharmuni.com/cover-letter-builder/'>
                                    Cover Letter Builder
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(5)} href='https://pharmuni.com/resume-builder/'>
                                    Resume Builder
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 d-flex flex-column justify-content-center align-items-center gap-2">
                <img src={MenuItems[activeItem].image} alt='feature_image' />
                {/* <p>{MenuItems[activeItem].description} </p> */}
            </div>
        </div>
    );
};

export default FeatureMenu;